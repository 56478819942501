<template>
  <div class="shortapp__container container">
    <div class="form-container">
   
   
   
   
    <!-- <div class="step first box-input" id="step1" v-show="step==1" @click="goNext(2)">
      <div class="headline" data-name="Purpose">
        60 Second Quick Application
      </div>
      <div class="box refinance">
        <img class="icon" src="assets/images/icon_stop.png" alt="stop">
        <div class="btn-txt">Click Here</div>
      </div>
    </div> -->

    <div class="step box-input" id="step1" v-show="step==1">
      <div class="headline" data-name="Use">
        HOW DID YOU HEAR ABOUT THE OPEN HOUSE?
      </div>
      <div class="box odd-display first-box" @click="chooseHearAbout('Laptop')">
        <img class="icon" height="83" src="assets/images/laptop-vector-stock_k14647485.jpg" alt="Laptop">
        <p class="txt small-mobile">Internet</p>
      </div>
      <div class="box second-box" @click="chooseHearAbout('Newspaper')">
        <img class="icon" height="83" src="assets/images/newspaper.png" alt="Newspaper">
        <p class="txt">Newspaper</p>
      </div>
      <div class="box third-box" @click="chooseHearAbout('Open House')">
        <img class="icon" height="83" src="assets/images/open-house.jpg" alt="Open House">
        <p class="txt">Sign</p>
      </div>
      <div class="box third-box" @click="chooseHearAbout('Neighbors')">
        <img class="icon" height="83" src="assets/images/New-Family-Mortgage-Neighbors--Icon.png" alt="Neighbors">
        <p class="txt">Neighbor</p>
      </div>
    </div>





    <div class="step box-input" id="step2" v-show="step==2">
      <div class="headline" data-name="Property Type">
        YOU EXPECT TO BUY WITHIN?
      </div>
      <div class="box" @click="timeFrame('one to two months')">
        <img class="icon" src="assets/images/months.png" alt="one to two months">
        <p class="txt">1-3 Months</p>
      </div>
      <div class="box" @click="timeFrame('three to six months')">
        <img class="icon" src="assets/images/months.png" alt="three to six months">
        <p class="txt">3-6 Months</p>
      </div>
      <div class="box" @click="timeFrame('six plus months')">
        <img class="icon" src="assets/images/months-2.png" alt="six plus months">
        <p class="txt">6+ Months</p>
      </div>
      <div class="box" @click="timeFrame('Just Looking')">
        <img class="icon" src="assets/images/lookinghome.png" alt="Just Looking">
        <p class="txt">Just Looking</p>
      </div>
      
    </div>

    <div class="step box-input" id="step3" v-show="step==3">
      <div class="headline">
        DO YOU HAVE A HOME TO SELL?
      </div>
      <div class="box" @click="saleHome('Yes')">
        <img class="icon" src="assets/images/yes.png" alt="yes">
        <p class="txt">Yes</p>
      </div>
      <div class="box" @click="saleHome('No')">
        <img class="icon" src="assets/images/no.png" alt="no">
        <p class="txt">No</p>
      </div>
      
    </div>

    <div class="step box-input" id="step4" v-show="step==4">
      <div class="headline">
        ARE YOU WORKING WITH A REALTOR?
      </div>
      <div class="box" @click="workingWithRealtor('Yes')">
        <img class="icon" src="assets/images/yes.png" alt="yes">
        <p class="txt">Yes</p>
      </div>
      <div class="box" @click="workingWithRealtor('No')">
        <img class="icon" src="assets/images/no.png" alt="no">
        <p class="txt">No</p>
      </div>
      
    </div>

    <div class="step box-input" id="step5" v-show="step==5">
      <div class="headline">
        WILL YOU BE OBTAINING A MORTGAGE?
      </div>
      <div class="box" @click="gettingMortgage('Yes')">
        <img class="icon" src="assets/images/yes.png" alt="yes">
        <p class="txt">Yes</p>
      </div>
      <div class="box" @click="gettingMortgage('No')">
        <img class="icon" src="assets/images/no.png" alt="no">
        <p class="txt">No</p>
      </div>
      
    </div>

    <div class="step text-input" id="step6" v-show="step==6">
      <div class="headline">
        Your Basic Information
      </div>
      <div class="form-horizontal inline-form-horizontal">
        <div class="form-group">
          <label class="col-sm-4">First Name:</label>
          <input name="First_Name" type="text" class="textfield col-sm-8" placeholder="First Name" required="required" aria-label="first name" v-model="profile.firstName">
        </div>
        <div class="form-group">
          <label class="col-sm-4">Last Name:</label>
          <input name="Last_Name" type="text" class="textfield col-sm-8" placeholder="Last Name" required="required" aria-label="last name" v-model="profile.lastName">
        </div>
        <div class="form-group">
          <label class="col-sm-4">Phone:</label>
          <input name="Phone" type="text" class="textfield col-sm-8 phone_validate"
                 placeholder="Phone" required="required" aria-label="phone" v-model="profile.phone" @input="onPhoneInput">
        </div>
        <div class="form-group">
          <label class="col-sm-4">Email:</label>
          <input name="Email" type="email" class="textfield col-sm-8" placeholder="Email" required="required" aria-label="email" v-model="profile.email">
        </div>

      </div>

      <button name="input" id="submit-form" type="button" class="sub-but fwd" @click="submit" :disabled="searchDisabled">Submit</button>
      
    </div>

    <div class="step last" id="step7" v-show="step==7">
      <div>
        <h1>Thank you for submitting your information.<br></h1>
      </div>
    </div>

    <div class="arrow-container" v-show="arrowShow"><a class="arrow-btn back" aria-label="back"><i class="fa fa-arrow-circle-left fa-2x" @click="goBefore"></i></a></div>
    <!-- <div class="arrow-container"><a href="#" class="arrow-btn back" aria-label="back"><i class="fa fa-arrow-circle-left fa-2x"></i></a> <a href="#" class="arrow-btn fwd" aria-label="next"><i class="fwd fa fa-arrow-circle-right fa-2x"></i></a></div> -->
  </div>
</div>
</template>

<script>
const axios = require('axios');
export default {
  name: 'Content',
  data: () => ({
    step: 1,
    searchDisabled: false,
    hearAbout: null,
    tFrame: null,
    hSale: null,
    realtor: null,
    mortgage: null,
    profile: {
      firstName: "",
      lastName: "",
      phone: null,
      email: null,

    }
  }),
  computed: {
    arrowShow() {
      return this.step != 1 && this.step != 7
    }
  },
  methods: {

    onPhoneInput(event) {
      const phoneFormat = '({0}{1}{2}) {3}{4}{5}-{6}{7}{8}{9}';
      let inputStripped = event.target.value.replace(/\D/g, '');
      let inputIsValid = !isNaN(parseInt(event.data));

      // Handle deletion
      if (event.inputType && event.inputType.includes('deleteContent')) {
        this.profile.phone = event.target.value;
        return;
      }

      // Prevent more than 10 digits or non-numeric input
      if (event.inputType === 'insertText' && (inputStripped.length > 10 || !inputIsValid)) {
        event.target.value = event.target.value.substring(0, event.target.value.length - 1);
        this.profile.phone = event.target.value;
        return;
      }

      if (inputStripped) {
        this.profile.phone = this.formatPhoneInput(inputStripped, phoneFormat);
      } else {
        this.profile.phone = '';
      }
    },

    formatPhoneInput(inputNumber, format) {
      let inputNumArr = inputNumber.split('');
      let formatVar = inputNumArr.length - 1;

      let replaceIndex = format.indexOf(`{${formatVar}}`) + 3;

      switch (formatVar) {
        case 2:
          replaceIndex += 2;
          break;
        case 5:
          replaceIndex += 1;
          break;
        default:
          break;
      }

      let formattedInput = format.substring(0, replaceIndex);

      for (let i = 0; i < inputNumArr.length; i++) {
        formattedInput = formattedInput.replace(`{${i}}`, inputNumArr[i]);
      }

      return formattedInput;
    },

    goNext(step) {
      // if((step===5 && this.estimatedHomeValue === "") || (step===6 && this.newLoanAmount === "") || (step===7 && (this.zipFinder === null || this.zipFinder == 0)) || (step===8 && (this.location.address === "" || this.location.city === "" || this.location.state === "" || (this.location.zipCode === null || this.location.zipCode == 0))) || (step===9 && (this.profile.firstName === "" || this.profile.lastName === "" || this.profile.email === "" || (this.profile.phone === null || this.profile.phone == 0))) || (step===13 && (this.coProfile.firstName === "" || this.coProfile.lastName === "" || this.coProfile.email === "" || (this.coProfile.phone === null || this.coProfile.phone == 0)))){
      //   alert("Please Complete Required Fields")
      //   return
      // }
      this.step = step
    },
    
    chooseHearAbout(hearChoice){
      this.hearAbout = hearChoice
      this.goNext(2)
    },
    timeFrame(frameChoice){
      this.tFrame = frameChoice
      this.goNext(3)
    },
    saleHome(saleAns){
      this.hSale = saleAns
      this.goNext(4)
    },
    workingWithRealtor(realtorAns){
      this.realtor = realtorAns
      this.goNext(5)
    },
    gettingMortgage(mortgageAns){
      this.mortgage = mortgageAns
      this.goNext(6)
    },

    goBefore() {
      this.step--
    },
    submit() {
      // var c = confirm("Are you really gonna submit?")
      if (
          ( (!this.profile.firstName || !this.profile.lastName || !this.profile.email || !this.profile.phone))
       ) {
        alert("Please fill out all the information to proceed.");
        return;
      }
      var c = 1;
      if(c){
        this.searchDisabled = true;
        const data = {
          profile: this.profile,
          foundUs: this.hearAbout, 
          yourTimeFrame: this.tFrame, 
          houseForSale: this.hSale, 
          workRealtor: this.realtor, 
          getMort: this.mortgage,
        }
        console.log(data)
        axios.post(process.env.VUE_APP_API_URL, data).then(res=>{
          if(res.data && res.data.res == "success")
          {
            // alert("Email sent")
            // location.reload()
            this.searchDisabled = false
            this.step = 7
          }
          else {
            console.log(res)
          }
        })
      }
    }


  }
}



</script>
